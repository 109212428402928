import React from 'react';

const teamMembers = [
  { name: 'Alexandr Semenets', linkedin: 'https://www.linkedin.com/in/alexandr-semenets/' },
  { name: 'Vlad Tsarov', linkedin: 'https://www.linkedin.com/in/vlad-tsarov-415445161/' },
  { name: 'Serhii Nosko', linkedin: 'https://www.linkedin.com/in/serhii-nosko-193339146/' },
  { name: 'Kirill Markin', linkedin: 'https://www.linkedin.com/in/kirill-markin-782aa9158/' },
  { name: 'Maxim Pilipenko (CEO)', linkedin: 'https://www.linkedin.com/in/maxim-pilipenko-5224a113b/' },
  { name: 'Ivan Serhiienko (Designer)', linkedin: 'https://www.linkedin.com/in/ivan-serhiienko-0488b71b1/' },
];

function TeamSection() {
  return (
    <section className="team-section">
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <h3>{member.name}</h3>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TeamSection;
