import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <h2>Why This Agency Was Created</h2>
      <p>
        There are already a lot of existing software development agencies. But I still decided to create my own because I
        worked a lot in this industry and I know A LOT of people from it as well. What I see is a lack of correct company culture.
        I based all the values of my company on everything the industry lacks today, and we WILL adhere to them—that's why we will
        surpass our competition.
      </p>
      <div className="focus-area">
        <p>Focusing on companies from Texas and Florida because we believe in American values and focus on the future, not the past.</p>
        <span role="img" aria-label="USA Flag">🇺🇸</span>
      </div>
    </footer>
  );
}

export default Footer;
