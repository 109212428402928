import React from 'react';

const projects = [
  'Namecheap.com (UI Kit)',
  'Roofstreet.io',
  'Bitfinex.com',
  'MyCointainer.com',
  'Doit.com',
  'Frichti.co',
];

function ProjectSection() {
  return (
    <section className="project-section">
      <h2>Our Projects</h2>
      <ul>
        {projects.map((project, index) => (
          <li key={index}>{project}</li>
        ))}
      </ul>
    </section>
  );
}

export default ProjectSection;
