import React from 'react';

import TeamSection from './TeamSection';
import ProjectSection from './ProjectSection';
import Footer from './Footer';

function App() {
  return (
    <div className="app-container">
      <header>
        <h1>Your Outsource Software Development Agency</h1>
        <p>Specializing in React, Node.js, Express.js, MongoDB, MERN Stack, and React Native</p>
        <button className="book-call-btn" onClick={() => window.open('https://calendly.com/piliponful/not-woke', '_blank')}>Book a Call</button>
      </header>

      <section>
        <h2>Why Choose Us?</h2>
        <ul>
          <li>We are hardworking.</li>
          <li>We believe in accountability.</li>
          <li>We believe in right incentives, so for any great job done we give bonuses.</li>
          <li>We believe in lean structure with less HRs.</li>
          <b>Choose new instead of old</b>
        </ul>
      </section>

      <TeamSection />
      <ProjectSection />

      <Footer />
    </div>
  );
}

export default App;
